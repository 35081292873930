import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import CopyrightFooter from 'components/CopyrightFooter'

const Question = (props) => {
  const handleChange = (panel) => (event, isExpanded) => {
    props.setExpanded(isExpanded ? panel : false)
  }

  return (
    <Accordion
      expanded={props.expanded === props.id}
      onChange={handleChange(props.id)}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`${props.id}-content`}
        id={`${props.id}-header`}>
        <Typography
          sx={{ flexShrink: 0 }}
          fontWeight={props.expanded === props.id && 'bold'}>
          {props.question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{props.answer}</Typography>
      </AccordionDetails>
    </Accordion>
  )
}

const HelpAndSupportPage = (props) => {
  const [expanded, setExpanded] = useState(false)

  const questions = [
    {
      question: 'How do I reset my password?',
      answer:
        'You can reset your password by clicking the "Forgot Password" link on the login page.',
    },
    {
      question: 'How do I change my email address?',
      answer:
        'You can change your email address by clicking the "Settings" link in the sidenav.',
    },
    {
      question: 'How do I change my password?',
      answer:
        'You can change your password by clicking the "Settings" link in the sidenav.',
    },
    {
      question: 'How do I delete my account?',
      answer:
        'You can delete your account by clicking the "Settings" link in the sidenav.',
    },
    {
      question: 'How do I contact support?',
      answer: 'You can contact support by emailing support@bigkittylabs.com',
    },
    {
      question: 'How do I submit a feature request?',
      answer:
        'You can submit a feature request by emailing requests@bigkittylabs.com',
    },
    {
      question: 'How do I report a bug?',
      answer: 'You can report a bug by emailing bugs@bigkittylabs.com',
    },
    {
      question: 'How do I request a refund?',
      answer: 'You can request a refund by emailing refunds@bigkittylabs.com',
    },
  ]

  return (
    <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh', 
    }}
    >
      <Typography
        variant="h4"
        textAlign="center"
        mb={2}
        sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>
        FAQs
      </Typography>
      {questions.map((question, index) => (
        <Question
          key={`${index}-question`}
          id={`${index}-question`}
          question={question.question}
          secondary={question.secondary}
          answer={question.answer}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      ))}
      <Box sx={{ px: { xs: 0, sm: 8, md: 20, lg: 30 }, mt: 4 }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          href="mailto:support@bigkittylabs.com">
          Still need help? Contact Us
        </Button>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <CopyrightFooter/>
    </Box>
  )
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(HelpAndSupportPage)
