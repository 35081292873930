import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Button,
  CircularProgress,
  Skeleton,
  TextField,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Tooltip,
  IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import * as _ from 'lodash'
import isValidEmail from 'helpers/isValidEmail'
import { setUser } from 'reducers/user'
import { setSnackbar } from 'reducers/ui'
import CopyrightFooter from 'components/CopyrightFooter'
import api from 'lib/api'

const SettingsPage = (props) => {
  const { user } = props
  const [tenantId, setTenantId] = useState(null)
  const [searches, setSearches] = useState([])
  const [loadingSearches, setLoadingSearches] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [original, setOriginal] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user && user.tenantId) {
      setTenantId(user.tenantId)
    }
  }, [user])

  useEffect(() => {
    const fetchNewsletters = async () => {
      try {
        setLoadingSearches(true)
        const newslettersResponse = await api.tenants.newsletters.get({
          tenantId: user.tenantId,
        })

        if (
          newslettersResponse.data &&
          Array.isArray(newslettersResponse.data.newsletters)
        ) {
          const newsletters = newslettersResponse.data.newsletters.map(
            (newsletter) => ({
              ...newsletter,
              selected: false,
            })
          )
          setSearches(newsletters)
        } else {
          console.warn('No newsletters found in the API response.')
        }
      } catch (error) {
        console.error('Error fetching newsletters:', error)
        props.setSnackbar({ message: error.message, severity: 'error' })
      } finally {
        setLoadingSearches(false)
      }
    }

    fetchNewsletters()
  }, [user, props])

  const handleSave = async () => {
    setLoading(true)
    try {
      setTimeout(() => {
        props.setUser({
          ...user,
          email,
          username,
          firstName,
          lastName,
          companyName,
        })
        setLoading(false)
        props.setSnackbar({
          message: 'Profile updated successfully!',
          severity: 'success',
        })
      }, 1000)
    } catch (e) {
      props.setSnackbar({ message: e.message, severity: 'error' })
      setLoading(false)
    }
  }

  const handleCheckboxChange = (id) => {
    setSearches((prevSearches) =>
      prevSearches.map((search) =>
        search.id === id ? { ...search, selected: !search.selected } : search
      )
    )
  }
  const handleDeleteIndividual = async (newsletterId) => {
    if (!window.confirm('Are you sure you want to delete this search?')) return

    setDeleting(true)
    try {
      await api.tenants.newsletters.delete({
        tenantId: tenantId,
        newsletterId: newsletterId,
      })
      setSearches((prevSearches) =>
        prevSearches.filter((s) => s.id !== newsletterId)
      )
      props.setSnackbar({
        message: 'Search deleted successfully!',
        severity: 'success',
      })
    } catch (error) {
      console.error('Error deleting search:', error)
      props.setSnackbar({ message: error.message, severity: 'error' })
    } finally {
      setDeleting(false)
    }
  }
  const handleDeleteSelected = async () => {
    const selectedNewsletters = searches.filter((s) => s.selected)
    if (selectedNewsletters.length === 0) {
      props.setSnackbar({
        message: 'No searches selected for deletion.',
        severity: 'info',
      })
      return
    }

    if (
      !window.confirm('Are you sure you want to delete the selected searches?')
    )
      return

    setDeleting(true)
    try {
      const deletePromises = selectedNewsletters.map((newsletter) =>
        api.tenants.newsletters.delete({
          tenantId: tenantId,
          newsletterId: newsletter.id,
        })
      )
      await Promise.all(deletePromises)
      setSearches((prevSearches) => prevSearches.filter((s) => !s.selected))
      props.setSnackbar({
        message: 'Selected searches deleted successfully!',
        severity: 'success',
      })
    } catch (error) {
      console.error('Error deleting selected searches:', error)
      props.setSnackbar({ message: error.message, severity: 'error' })
    } finally {
      setDeleting(false)
    }
  }
  useEffect(() => {
    if (user) {
      setEmail(user.email)
      setUsername(user.username)
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setCompanyName(user.companyName)

      setOriginal({
        email: user.email,
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        companyName: user.companyName,
      })
    }
  }, [user])

  return (
    <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh', 
    }}
    >
      <Typography
        variant="h4"
        mb={2}
        sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>
        Settings
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Box>
          <Typography
            variant="h6"
            mb={2}
            sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>
            Account Management
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: 2,
              alignItems: { xs: 'center', md: 'unset' },
            }}>
            <TextField
              label="Username"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 3, maxWidth: 600 }}
              InputProps={{
                sx: {
                  backgroundColor: 'background.paper',
                },
              }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 3, maxWidth: 600 }}
              InputProps={{
                sx: {
                  backgroundColor: 'background.paper',
                },
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {password !== '' && (
              <TextField
                label="Confirm New Password"
                type="password"
                variant="outlined"
                size="small"
                fullWidth
                error={confirmPassword !== password}
                helperText={
                  confirmPassword !== password ? 'Passwords do not match.' : ''
                }
                sx={{ mb: 3, maxWidth: 600 }}
                InputProps={{
                  sx: {
                    backgroundColor: 'background.paper',
                  },
                }}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            )}
            <TextField
              label="Email"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 3, maxWidth: 600 }}
              InputProps={{
                sx: {
                  backgroundColor: 'background.paper',
                },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="First Name"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 3, maxWidth: 600 }}
              InputProps={{
                sx: {
                  backgroundColor: 'background.paper',
                },
              }}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 3, maxWidth: 600 }}
              InputProps={{
                sx: {
                  backgroundColor: 'background.paper',
                },
              }}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              label="Company Name"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ maxWidth: 600 }}
              InputProps={{
                sx: {
                  backgroundColor: 'background.paper',
                },
              }}
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              px: { xs: 10, sm: 20, md: 'unset' },
              mt: 2,
              maxWidth: { md: 600 },
            }}>
            <Button
              disabled={
                loading ||
                password !== confirmPassword ||
                (!_.isEqual(original, {
                  email,
                  username,
                  firstName,
                  lastName,
                  companyName,
                }) &&
                  !isValidEmail(email)) ||
                (_.isEqual(original, {
                  email,
                  username,
                  firstName,
                  lastName,
                  companyName,
                }) &&
                  password === '')
              }
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSave}
              sx={{ display: 'flex', gap: 1 }}>
              {loading && <CircularProgress size={20} color="inherit" />}
              {loading ? 'Saving...' : 'Save Changes'}
            </Button>
          </Box>
        </Box>
        <Box>
          <Typography variant="h6" mb={2} sx={{ fontWeight: 'bold' }}>
            Newsletter
          </Typography>
          {loadingSearches ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Select</TableCell>
                      <TableCell>Search Name</TableCell>
                      <TableCell>Keywords</TableCell>
                      <TableCell>Agency</TableCell>
                      <TableCell>NAICS Code</TableCell>
                      <TableCell>Type of Set Aside</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searches.length > 0 ? (
                      searches.map((search) => (
                        <TableRow key={search.id}>
                          <TableCell>
                            <Checkbox
                              checked={search.selected || false}
                              onChange={() => handleCheckboxChange(search.id)}
                            />
                          </TableCell>
                          <TableCell>{search.name}</TableCell>
                          <TableCell>{search.query}</TableCell>
                          <TableCell>{search.agency}</TableCell>
                          <TableCell>{search.naicsCode}</TableCell>
                          <TableCell>{search.typeOfSetAside}</TableCell>
                          <TableCell>
                            <Tooltip title="Delete search">
                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  handleDeleteIndividual(search.id)
                                }
                                disabled={deleting}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          No searches found.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDeleteSelected}
                sx={{ mt: 2 }}
                disabled={deleting || !searches.some((s) => s.selected)}>
                {deleting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Delete Selected Searches'
                )}
              </Button>
            </>
          )}
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography
            variant="h6"
            mb={2}
            sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>
            Plan Management
          </Typography>
          <Skeleton variant="rectangular" width="100%" height={150} />
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <CopyrightFooter />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  setUser,
  setSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage)
