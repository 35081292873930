import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: null,
    email: null,
    username: null,
    firstName: null,
    lastName: null,
    companyName: null,
    role: null,
    token: null,
    isActive: null,
    tenantId: null, 
  },
  reducers: {
    setToken(state, action) {
      state.token = action.payload
    },
    setUser(state, action) {
      const {
        id,
        email,
        username,
        firstName,
        lastName,
        companyName,
        role,
        token,
        isActive,
        tenantId,
      } = action.payload
      state.id = id
      state.email = email
      state.username = username
      state.firstName = firstName
      state.lastName = lastName
      state.companyName = companyName
      state.role = role
      state.token = token
      state.isActive = isActive
      state.tenantId = tenantId
    },
    clearUser(state) {
      state.id = null
      state.email = null
      state.username = null
      state.firstName = null
      state.lastName = null
      state.companyName = null
      state.role = null
      state.token = null
      state.isActive = null
      state.tenantId = null
    },

  },
})

const { actions, reducer } = userSlice

export const { setToken, setUser, clearUser } = actions

export default reducer
