

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  samRows: [],
  sbirRows: [],
  closedSbirRows: [],
  selectedFile: '', 
};

export const rfpSlice = createSlice({
  name: 'rfp',
  initialState,
  reducers: {
    setSamRows: (state, action) => {
      state.samRows = action.payload;
    },
    setSbirRows: (state, action) => {
      state.sbirRows = action.payload;
    },
    setClosedSbirRows: (state, action) => {
      state.closedSbirRows = action.payload;
    },
    clearRfpData: (state) => {
      state.samRows = [];
      state.sbirRows = [];
      state.closedSbirRows = [];
      state.selectedFile = ''; 
    },
    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload; 
    },
  },
});

export const {
  setSamRows,
  setSbirRows,
  setClosedSbirRows,
  clearRfpData,
  setSelectedFile, 
} = rfpSlice.actions;

export default rfpSlice.reducer;
