import React from 'react';
import { Typography, Link, Box } from '@mui/material';
import styled from '@emotion/styled';
import BKLLogo from '../assets/Bkl-logo.png';

const FooterContainer = styled(Box)({
  backgroundColor: '#879FFF',
  marginTop: '20px',
  width: '100%',
  height: '80px',
  padding: '10px 20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
  bottom: 0,         
});

const Logo = styled('img')({
  width: '50px',
  height: '50px',
});

const CopyrightFooter = () => {
  return (
    <FooterContainer>
      <Logo src={BKLLogo} alt="BKL Logo" />
      <Typography variant="body2" align="center" color="white">
        {'Copyright © '}
        <Link color="inherit" href="https://bigkittylabs.com/" style={{ color: '#fff' }}>
          Big Kitty Labs, Inc
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </FooterContainer>
  );
};

export default CopyrightFooter;
